import {
  StickerOrderFragment,
  type StorefrontAddCartLinesMutation,
  type StorefrontCartLineInput,
} from "../MapmakerApi";
import { useShopifyStickerData } from "./stickerHooks";
import { useStorefrontAddCartLines } from "./storefrontCheckoutHooks";
import { MutationResult } from "@apollo/client";
import { trackGtmEvent } from "../../lib/gtm";

export function useAddStickerOrderToCart(): [
  (stickerOrder: StickerOrderFragment) => Promise<void>,
  MutationResult<StorefrontAddCartLinesMutation>
] {
  const [addCartLines, status] = useStorefrontAddCartLines();
  const { stickerSetMap } = useShopifyStickerData();

  /**
   * The method exposed to consumers. Given a sticker order, add it to the users cart.
   */
  async function addStickerOrderToCart(stickerOrder: StickerOrderFragment) {
    type LineItemInputMap = { [key: string]: StorefrontCartLineInput };
    const lineItems: LineItemInputMap =
      stickerOrder.stickers.reduce<LineItemInputMap>((lineItemMap, sticker) => {
        // The null/undefined checks are for backward compatibility. Soon we should remove the checks
        // and make the graphql properties non-nullable.
        if (
          sticker.quantityWithMoney === undefined ||
          sticker.quantityWithMoney === null
        ) {
          sticker.quantityWithMoney = sticker.quantity;
        }

        const stickerSet = stickerSetMap[stickerOrder.outputType];
        const stickerVariant = stickerSet.variants.find(
          (variant) => variant.sizeClass === sticker.sizeClass
        );

        //
        // Add items paid for with money
        //
        if (sticker.quantityWithMoney > 0) {
          if (!lineItemMap[stickerVariant.shopifyVariantId]) {
            lineItemMap[stickerVariant.shopifyVariantId] = {
              merchandiseId: stickerVariant.shopifyVariantId,
              quantity: sticker.quantityWithMoney,
              attributes: [
                {
                  key: "Order ID",
                  value: stickerOrder.id,
                },
              ],
            };
          } else {
            lineItemMap[stickerVariant.shopifyVariantId].quantity +=
              sticker.quantityWithMoney;
          }
        }

        //
        // Add stickers paid for with tokens
        //
        if (
          sticker.quantityWithTokens !== undefined &&
          sticker.quantityWithTokens !== null &&
          sticker.quantityWithTokens > 0
        ) {
          if (!stickerSet.tokenRedemptionVariantId) {
            throw new Error(`Error redeeming sticker tokens.`);
          }
          if (!lineItemMap[stickerSet.tokenRedemptionVariantId]) {
            lineItemMap[stickerSet.tokenRedemptionVariantId] = {
              merchandiseId: stickerSet.tokenRedemptionVariantId,
              quantity: sticker.quantityWithTokens,
              attributes: [
                {
                  key: "Order ID",
                  value: stickerOrder.id,
                },
                {
                  key: "Tokens Used",
                  value: (
                    sticker.quantityWithTokens * stickerVariant.tokens
                  ).toString(),
                },
              ],
            };
          } else {
            // Increment the line item quantity
            lineItemMap[stickerSet.tokenRedemptionVariantId].quantity +=
              sticker.quantityWithTokens;
            // Increment the "Tokens Used" quantity.
            lineItemMap[stickerSet.tokenRedemptionVariantId].attributes =
              lineItemMap[stickerSet.tokenRedemptionVariantId].attributes?.map(
                (attr) => {
                  if (attr.key === "Tokens Used") {
                    const newValue =
                      parseFloat(attr.value) +
                      (sticker.quantityWithTokens ?? 1) * stickerVariant.tokens;
                    return {
                      key: attr.key,
                      value: newValue.toString(),
                    };
                  } else {
                    return attr;
                  }
                }
              );
          }
        }

        return lineItemMap;
      }, {});

    await addCartLines(Object.values(lineItems));

    trackGtmEvent({
      event: "mapmaker.add-stickers-to-cart",
      numStickers: stickerOrder.stickers.length,
    });
  }

  return [addStickerOrderToCart, status];
}
