import { isDefaultVariantOption } from "../../lib/storefront";
import { ucWords } from "../../lib/stringUtils";
import { StandardLineDetailsProps } from "./StandardLine";
import "./StandardLineItemDetails.css";

export default function StandardLineDetails({
  line,
  exclude = [],
}: StandardLineDetailsProps) {
  return (
    <dl id="standard-line-item-details">
      {line.merchandise.selectedOptions.map(({ name, value }) => {
        if (isDefaultVariantOption(name, value)) {
          return null;
        }
        if (exclude.includes(name)) {
          return null;
        }
        return (
          <div key={name}>
            <dt>{name}</dt>
            <dd className="value">{value}</dd>
          </div>
        );
      })}
      {line.attributes.map(({ key, value }) => {
        if (key === "Gift Note") {
          return null;
        }
        if (exclude.includes(key)) {
          return null;
        }
        return (
          <div key={key}>
            <dt>{ucWords(key.replace("-", " "))}</dt>
            <dd className="value">{value}</dd>
          </div>
        );
      })}
    </dl>
  );
}
