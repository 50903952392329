import clsx from "clsx";
import { gql } from "@apollo/client";
import React, { ReactNode, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { LoadingSpinner } from "@mapmaker/ui";
import {
  StorefrontDiscountAllocationFragment,
  StorefrontMoneyV2,
  useStorefrontUpdateCartLinesMutation,
} from "../../client/MapmakerApi";
import PriceWithDiscount from "./PriceWithDiscount";
import { useStorefrontCartId } from "../../client/storefront/storefrontCheckoutHooks";
import "./LineItem.css";

gql`
  mutation storefrontUpdateCheckoutLineItems(
    $checkoutId: ID!
    $lineItems: [StorefrontCheckoutLineItemUpdateInput!]!
  ) {
    storefrontCheckoutLineItemsUpdate(
      checkoutId: $checkoutId
      lineItems: $lineItems
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  mutation storefrontUpdateCartLines(
    $cartId: ID!
    $lines: [StorefrontCartLineUpdateInput!]!
  ) {
    storefrontCartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...StorefrontCartHeavy
      }
    }
  }
`;
export type LineItemProps = {
  children: React.ReactNode;
  title: string;
  onProductNameClick?(): any;
  thumbnail?: string | ReactNode;
  price: StorefrontMoneyV2;
  loading?: boolean;
  discountAllocations?: StorefrontDiscountAllocationFragment[];
  lineIds: string[];
};

export default function LineItem({
  children,
  title,
  onProductNameClick,
  thumbnail = null,
  price,
  loading = false,
  discountAllocations = [],
  lineIds,
}: LineItemProps) {
  const cartId = useStorefrontCartId();
  const [updateCartLine, { loading: removing }] =
    useStorefrontUpdateCartLinesMutation();
  loading = loading || removing;

  const thumbnailContent = useMemo(() => {
    if (typeof thumbnail === "string") {
      return <img src={thumbnail} alt={title} />;
    } else if (thumbnail) {
      return thumbnail;
    } else {
      return null;
    }
  }, [thumbnail, title]);

  async function onRemoveClick() {
    try {
      // Removing via update keeps us from weird issues with discount allocations. If we remove with
      // the standard removeLineItem it will remove other instances which were shown to the user as
      // separate line items.
      await updateCartLine({
        variables: {
          // @ts-expect-error
          cartId: cartId as string,
          lines: lineIds.map((lineId) => ({
            id: lineId,
            quantity: 0,
          })),
        },
      });
    } catch (e) {
      alert("Error removing item. Please try again.");
    }
  }

  return (
    <div id="line-item" className={clsx({ loading, removed: removing })}>
      {loading && (
        <div className="loader">
          <LoadingSpinner />
        </div>
      )}
      <div className="thumbnail">{thumbnailContent}</div>
      <div className="content">
        <div className="header">
          <span className="title">
            {onProductNameClick ? (
              <span className="subtle-link" onClick={onProductNameClick}>
                {title}
              </span>
            ) : (
              title
            )}
          </span>

          <div className="price">
            <PriceWithDiscount
              price={price}
              discountAllocations={discountAllocations}
            />
          </div>
        </div>
        <div className="main">{children}</div>
        <div className="remove">
          <span onClick={onRemoveClick}>
            <Icon name="trash" /> remove
          </span>
        </div>
      </div>
    </div>
  );
}
