import { gql } from "@apollo/client";
import {
  StorefrontCheckoutHeavyFragment,
  StorefrontCheckoutLineItemInput,
  StorefrontAttributeInput,
  StorefrontCheckoutLineItemHeavyFragment,
  StorefrontAddCheckoutLineItemsMutationHookResult,
  useStorefrontCartLinesRemoveMutation,
  type StorefrontCartLineHeavyFragment,
  type StorefrontCartHeavyFragment,
  type StorefrontCartLineInput,
} from "../../client/MapmakerApi";
import {
  useStorefrontAddCartLines,
  useStorefrontCartHeavy,
} from "../../client/storefront/storefrontCheckoutHooks";
import { attributeToAttributeInput } from "./attributeUtils";

gql`
  mutation storefrontReplaceCheckoutLineItems(
    $checkoutId: ID!
    $lineItems: [StorefrontCheckoutLineItemInput!]!
  ) {
    storefrontCheckoutLineItemsReplace(
      checkoutId: $checkoutId
      lineItems: $lineItems
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }
`;

/**
 * This method exists because updating a line item using checkoutLineItemsUpdate will always
 * update every unit of the line item (when quantity is greater than 1). So if you only want to
 * update only some of those units in a single call then updating all the items is required.
 */
export function useUpdatePartialLine(): [
  (
    lineId: string,
    customAttributes: StorefrontAttributeInput[],
    quantityToUpdate: number
  ) => Promise<any>,
  StorefrontAddCheckoutLineItemsMutationHookResult[1]
] {
  const { cart } = useStorefrontCartHeavy();
  const [removeCartLines, removeCartLinesStatus] =
    useStorefrontCartLinesRemoveMutation();
  const [addCartLines, addCartLinesStatus] = useStorefrontAddCartLines();

  const updateCheckoutLineItem = async (
    lineItemId: string,
    customAttributes: StorefrontAttributeInput[],
    quantityToUpdate: number
  ) => {
    if (!cart) {
      throw new Error(
        `Error updating your cart. Please contact us if the problem persists.`
      );
    }

    // Combine line items by ID with quantities combined
    const lineItemsGroupedById = groupCartLinesById(cart.lines);

    const existingLineItem = lineItemsGroupedById.find(
      (lineItem) => lineItem.id === lineItemId
    );
    if (!existingLineItem) {
      throw new Error(
        `Error updating your cart. Please contact us if the problem persists.`
      );
    }
    // Only update up to the existing quantity.
    quantityToUpdate = Math.min(quantityToUpdate, existingLineItem.quantity);

    const newLines: StorefrontCartLineInput[] = Object.values(
      lineItemsGroupedById
    ).flatMap((lineItem) => {
      const variantId = lineItem.merchandise?.id as string;
      const existingCustomAttributes = lineItem.attributes.map(
        attributeToAttributeInput
      );
      if (lineItem.id === existingLineItem.id) {
        const newLineItem = {
          quantity: quantityToUpdate,
          merchandiseId: variantId,
          attributes: customAttributes,
        };
        if (lineItem.quantity - quantityToUpdate === 0) {
          return newLineItem;
        } else {
          return [
            // Old instance - only if there are leftovers
            {
              quantity: lineItem.quantity - quantityToUpdate,
              merchandiseId: variantId,
              attributes: existingCustomAttributes,
            },
            // New line item
            newLineItem,
          ];
        }
      } else {
        // Don't touch the other items
        return {
          quantity: lineItem.quantity,
          merchandiseId: variantId,
          attributes: existingCustomAttributes,
        };
      }
    });

    // Remove the old.
    await removeCartLines({
      variables: {
        // @ts-expect-error
        cartId: cart.id,
        // @ts-expect-error
        lineIds: [lineItemId],
      },
    });

    // Add the new.
    await addCartLines(newLines);
  };

  return [
    updateCheckoutLineItem,
    {
      loading: removeCartLinesStatus.loading || addCartLinesStatus.loading,
      called: removeCartLinesStatus.called || addCartLinesStatus.called,
      client: removeCartLinesStatus.client || addCartLinesStatus.client,
      reset: removeCartLinesStatus.reset || addCartLinesStatus.reset,
    },
  ];
}

/*
export function getLineItemTrueQuantity(
  checkout: CheckoutLightFragment | undefined,
  lineItem: Pick<CheckoutLineItem, "id" | "quantity">
): number {
  if (!checkout) {
    return lineItem.quantity;
  } else {
    const realId = getTrueId(lineItem.id);
    return checkout.lineItems.edges.reduce<number>((total, { node }) => {
      const matchesId = Math.abs(getTrueId(node.id) - realId) <= 1;
      return total + (matchesId ? node.quantity : 0);
    }, 0);
  }
}

function getTrueId(id: string): number {
  return parseInt(
    atob(id)
      .split("//")[1]
      .split("/")[2]
      .split("?")[0]
  );
}
*/

// #DEPRECATED
function groupLineItemsById(
  lineItems: StorefrontCheckoutHeavyFragment["lineItems"]
): StorefrontCheckoutLineItemHeavyFragment[] {
  const groups = lineItems.edges.reduce<{
    [id: string]: StorefrontCheckoutLineItemHeavyFragment;
  }>((groups, { node: lineItem }) => {
    if (groups[lineItem.id]) {
      const existingLineItem = groups[lineItem.id];
      return {
        [lineItem.id]: {
          ...existingLineItem,
          quantity: existingLineItem.quantity + lineItem.quantity,
        },
        ...groups,
      };
    } else {
      return {
        [lineItem.id]: lineItem,
        ...groups,
      };
    }
  }, {});
  return Object.values(groups);
}

function groupCartLinesById(
  lines: StorefrontCartHeavyFragment["lines"]
): StorefrontCartLineHeavyFragment[] {
  const groups = lines.edges.reduce<{
    [id: string]: StorefrontCartLineHeavyFragment;
  }>((groups, { node: lineItem }) => {
    if (groups[lineItem.id]) {
      const existingLineItem = groups[lineItem.id];
      return {
        [lineItem.id]: {
          ...existingLineItem,
          quantity: existingLineItem.quantity + lineItem.quantity,
        },
        ...groups,
      };
    } else {
      return {
        [lineItem.id]: lineItem,
        ...groups,
      };
    }
  }, {});
  return Object.values(groups);
}
