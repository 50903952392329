import { useMemo, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { FaRegQuestionCircle } from "@react-icons/all-files/fa/FaRegQuestionCircle";
import { IconButton } from "@mapmaker/ui";
import { useGetStickerOfferQuery } from "../../../client/MapmakerApi";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import LoadingPage from "../../shared/LoadingPage";
import MessagePage from "../../shared/MessagePage";
import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import StickerOfferGrid from "./StickerOfferGrid";
import OrderStickersContextPanel from "./OrderStickersContextPanel";
import {
  createOrderStickersStore,
  StickerCartState,
} from "./orderStickersStore";
import "./MapmakerOrderStickers.css";
import StickerOrderMessages from "./StickerOrderMessages";
import StickerOrderControls from "./StickerOrderControls";
import { useShopifyStickerData } from "../../../client/storefront/stickerHooks";
import { useStickerOrderLines } from "../../../lib/storefront/stickerCheckoutUtils";
import StickerOutputDescriptionModal from "./StickerOutputDescriptionModal";
import { setShowOutputHelp } from "./stickerOrderSettingsReducer";
import { gql } from "@apollo/client";
import { useProjectFile } from "../../project/useProjectState";
import ProjectToolbar from "../../project/ProjectToolbar";
import "../../project/context/DeprecatedContextPanelLayout.css";

gql`
  fragment StickerOffer on StickerOffer {
    stickers {
      ...StickerOfferItem
    }
  }

  fragment StickerOfferItem on StickerOfferItem {
    openingId
    sizeClass
    render {
      url
    }
  }

  query getStickerOffer($input: StickerOfferInput!) {
    stickerOffer(input: $input) {
      ...StickerOffer
    }
  }
`;

export default function MapmakerOrderStickers() {
  const { stickersConfig } = useMapmakerAppConfig();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const file = useProjectFile();
  const {
    data: { stickerOffer } = {},
    loading: loadingStickerOffer,
    error,
  } = useGetStickerOfferQuery({
    variables: {
      input: {
        fileId: file.id,
      },
    },
    fetchPolicy: "no-cache",
  });
  const { stickerSetMap, loading: loadingShopifyStickerData } =
    useShopifyStickerData();
  const {
    stickerOrderLines,
    stickerTokensInCart,
    loading: loadingCartLineItems,
  } = useStickerOrderLines();

  const groups = useMemo(() => {
    return stickerOrderLines.map((group) => ({
      fileId: group.fileId,
      stickerCount: group.lines.reduce((sum, line) => sum + line.quantity, 0),
    }));
  }, [stickerOrderLines]);

  const cartState: StickerCartState = {
    groups,
    stickerTokensInCart,
  };

  const design = file?.design;

  // Once we're done loading we want to create the store once and be done with it.  Otherwise our
  // apollo data from the above methods may refresh causing the store to reset.
  const store = useMemo(
    () =>
      design &&
      file &&
      stickerOffer &&
      stickerSetMap &&
      createOrderStickersStore(
        design,
        file,
        stickerOffer,
        stickerSetMap,
        cartState,
        stickersConfig.shippingCost,
        stickersConfig.freeShippingThreshold
      ),
    [design, file, stickerOffer, stickerSetMap]
  );

  if (
    loadingStickerOffer ||
    loadingCartLineItems ||
    loadingShopifyStickerData
  ) {
    return (
      <LoadingPage
        message="Generating Stickers"
        secondary="This may take a moment, but it's faster than cutting them out with scissors!"
      />
    );
  } else if (error) {
    return (
      <MessagePage icon="warning circle">
        {error.graphQLErrors[0].message}
      </MessagePage>
    );
  } else if (!file) {
    return <MessagePage icon="question circle">Map not found.</MessagePage>;
  } else if (!stickerOffer) {
    return (
      <MessagePage icon="question circle">
        Error generating stickers. Contact us if the problem persists.
      </MessagePage>
    );
  }

  return (
    <Provider store={store}>
      <MapmakerLayout>
        <MapmakerLayout.TopBar>
          <MapmakerTopBar
            breadcrumbs={[
              {
                label: "Order Stickers",
              },
            ]}
            after={<HelpButton />}
          />
        </MapmakerLayout.TopBar>
        <MapmakerLayout.Main
          className="deprecated-context-panel-layout"
          nav={<ProjectToolbar selected="output" />}
        >
          <MapmakerLayout.ContextPanel>
            <OrderStickersContextPanel />
          </MapmakerLayout.ContextPanel>
          <MapmakerLayout.Content className="mapmaker-order-stickers">
            <StickerOutputDescriptionModal
              open={showHelpModal}
              onClose={() => setShowHelpModal(false)}
            />
            <StickerOrderMessages />
            <StickerOrderControls />
            <div className="sticker-grid">
              <StickerOfferGrid />
            </div>
          </MapmakerLayout.Content>
        </MapmakerLayout.Main>
      </MapmakerLayout>
    </Provider>
  );
}

function HelpButton() {
  const dispatch = useDispatch();
  return (
    <IconButton
      className="order-stickers-help-button"
      Icon={FaRegQuestionCircle}
      size={24}
      onClick={() => dispatch(setShowOutputHelp(true))}
    />
  );
}
