import { useMemo } from "react";
import { useStorefrontCartHeavy, useStorefrontCheckoutHeavy } from "../..";
import {
  StorefrontCheckoutHeavyFragment,
  StorefrontCheckoutLineItem,
  StorefrontProduct,
  StorefrontCheckoutLineItemHeavyFragment,
  type StorefrontCartLineHeavyFragment,
  type StorefrontCartLine,
  type StorefrontCartHeavyFragment,
} from "../../client/MapmakerApi";
import { getAttributeValueByKey } from "./attributeUtils";
import { isPurchaseStickerTokensProduct } from "./purchaseStickerTokenUtils";

// #DEPRECATED
export type StickerOrderLineItemGroup = {
  fileId: string;
  orderId: string;
  lineItems: StorefrontCheckoutLineItemHeavyFragment[];
};

export type StickerOrderLineGroup = {
  fileId: string;
  orderId: string;
  lines: StorefrontCartLineHeavyFragment[];
};

// #DEPRECATED
export function isStickerLineItem(
  lineItem: StorefrontCheckoutLineItemHeavyFragment
): boolean {
  return (
    lineItem.variant?.sku.startsWith("mapmaker.sticker.") ||
    lineItem.variant?.sku.startsWith("pm.sticker.")
  );
}

export function isStickerOrderLine(
  line: StorefrontCartLineHeavyFragment
): boolean {
  return line.merchandise?.sku.startsWith("mapmaker.sticker.");
}

type GroupStickerLineItemsResult = {
  standardLineItems: StorefrontCheckoutLineItemHeavyFragment[];
  purchaseStickerTokensLineItems: StorefrontCheckoutLineItemHeavyFragment[];
  stickerOrderLineItems: StickerOrderLineItemGroup[];
};

// #DEPRECATED
export function groupStickerLineItems(
  lineItems: StorefrontCheckoutLineItemHeavyFragment[] | undefined
): GroupStickerLineItemsResult {
  return (lineItems || []).reduce<GroupStickerLineItemsResult>(
    (result, lineItem) => {
      const orderId = getOrderId(lineItem);
      if (isStickerLineItem(lineItem) && orderId) {
        const existingGroup = result.stickerOrderLineItems.find(
          (existingLineItem) => existingLineItem.orderId === orderId
        );
        if (existingGroup) {
          existingGroup.lineItems.push(lineItem);
        } else {
          result.stickerOrderLineItems.push({
            fileId: orderId.split("-")[0],
            orderId,
            lineItems: [lineItem],
          });
        }
      } else if (
        isPurchaseStickerTokensProduct(
          lineItem.variant?.product as StorefrontProduct
        )
      ) {
        result.purchaseStickerTokensLineItems.push(lineItem);
      } else {
        result.standardLineItems.push(lineItem);
      }
      return result;
    },
    {
      standardLineItems: [],
      purchaseStickerTokensLineItems: [],
      stickerOrderLineItems: [],
    }
  );
}

type GroupStickerLinesResult = {
  standardLines: StorefrontCartLineHeavyFragment[];
  purchaseStickerTokensLines: StorefrontCartLineHeavyFragment[];
  stickerOrderLines: StickerOrderLineGroup[];
};

export function groupStickerLines(
  lines: StorefrontCartLineHeavyFragment[] | undefined
): GroupStickerLinesResult {
  return (lines || []).reduce<GroupStickerLinesResult>(
    (result, line) => {
      const orderId = getOrderIdCart(line);
      if (isStickerOrderLine(line)) {
        const existingGroup = result.stickerOrderLines.find(
          (existingLine) => existingLine.orderId === orderId
        );
        if (existingGroup) {
          existingGroup.lines.push(line);
        } else {
          result.stickerOrderLines.push({
            fileId: line.merchandise?.sku.split("-")[0],
            orderId,
            lines: [line],
          });
        }
      } else {
        result.standardLines.push(line);
      }
      return result;
    },
    {
      standardLines: [],
      purchaseStickerTokensLines: [],
      stickerOrderLines: [],
    }
  );
}

const ORDER_ID_KEY = "Order ID";

// #DEPRECATED
function getOrderId(
  lineItem: Pick<StorefrontCheckoutLineItem, "customAttributes">
): string | undefined {
  const orderIdAttribute = lineItem.customAttributes.find(
    (attribute) => attribute.key === ORDER_ID_KEY
  );
  if (orderIdAttribute) {
    return orderIdAttribute.value ?? undefined;
  } else {
    return undefined;
  }
}

function getOrderIdCart(
  line: Pick<StorefrontCartLine, "attributes">
): string | undefined {
  const orderIdAttribute = line.attributes.find(
    (attribute) => attribute.key === ORDER_ID_KEY
  );
  if (orderIdAttribute) {
    return orderIdAttribute.value ?? undefined;
  } else {
    return undefined;
  }
}

// #DEPRECATED
export function useStickerOrderLineItems() {
  const { checkout, loading, error } = useStorefrontCheckoutHeavy();
  const { stickerOrderLineItems } = useMemo(
    () =>
      groupStickerLineItems(checkout?.lineItems.edges.map(({ node }) => node)),
    [checkout]
  );

  const stickerTokensInCart = useMemo(() => {
    return (
      checkout?.lineItems.edges.reduce((tokens, { node: lineItem }) => {
        return (
          tokens +
          parseFloat(
            getAttributeValueByKey(
              lineItem.customAttributes,
              "Tokens Used",
              "0"
            )
          )
        );
      }, 0) ?? 0
    );
  }, [checkout?.lineItems]);

  return {
    stickerOrderLineItems,
    stickerTokensInCart,
    loading: loading,
    error: error,
  };
}

export function useStickerOrderLines() {
  const { cart, loading, error } = useStorefrontCartHeavy();
  const { stickerOrderLines } = useMemo(
    () => groupStickerLines(cart?.lines.edges.map(({ node }) => node)),
    [cart]
  );

  const stickerTokensInCart = useMemo(() => {
    return (
      cart?.lines.edges.reduce((tokens, { node: line }) => {
        return (
          tokens +
          parseFloat(
            getAttributeValueByKey(line.attributes, "Tokens Used", "0")
          )
        );
      }, 0) ?? 0
    );
  }, [cart?.lines]);

  return {
    stickerOrderLines,
    stickerTokensInCart,
    loading: loading,
    error: error,
  };
}

// #DEPRECATED
export function totalStickerTokensInCheckout(
  checkout: StorefrontCheckoutHeavyFragment
): number {
  return checkout.lineItems.edges.reduce(
    (total, { node: lineItem }) =>
      total +
      parseFloat(
        getAttributeValueByKey(lineItem.customAttributes, "Tokens Used", "0")
      ),
    0
  );
}

export function totalStickerTokensInCart(
  cart: StorefrontCartHeavyFragment
): number {
  return cart.lines.edges.reduce(
    (total, { node: line }) =>
      total +
      parseFloat(getAttributeValueByKey(line.attributes, "Tokens Used", "0")),
    0
  );
}

// #DEPRECATED
export function totalStickersWithoutTokenInCheckout(
  checkout: StorefrontCheckoutHeavyFragment
): number {
  return checkout.lineItems.edges.reduce(
    (total, { node: lineItem }) =>
      total +
      (isStickerLineItem(lineItem) &&
      getAttributeValueByKey(lineItem.customAttributes, "Tokens Used", "0") ===
        "0"
        ? lineItem.quantity
        : 0),
    0
  );
}

export function totalStickersWithoutTokenInCart(
  cart: StorefrontCartHeavyFragment
): number {
  return cart.lines.edges.reduce(
    (total, { node: line }) =>
      total +
      (isStickerOrderLine(line) &&
      getAttributeValueByKey(line.attributes, "Tokens Used", "0") === "0"
        ? line.quantity
        : 0),
    0
  );
}
