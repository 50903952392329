import * as Sentry from "@sentry/react";
import useLiveLocalStorage from "../../lib/hooks/useLiveLocalStorage";
import {
  FetchResult,
  gql,
  MutationResult,
  QueryHookOptions,
} from "@apollo/client";
import { useAuthContext } from "@tbl/aws-auth";
import {
  StorefrontAddCheckoutLineItemsMutationVariables,
  useStorefrontCreateCheckoutMutation,
  useStorefrontGetCheckoutHeavyQuery,
  useStorefrontAddCheckoutLineItemsMutation,
  useStorefrontGetCheckoutLightQuery,
  StorefrontCheckoutHeavyFragment,
  StorefrontCheckoutLightFragment,
  StorefrontAddCheckoutLineItemsMutation,
  StorefrontGetCheckoutHeavyQuery,
  Exact,
  StorefrontGetCheckoutLightQuery,
  StorefrontGetCartLightQuery,
  StorefrontCartLightFragment,
  useStorefrontGetCartLightQuery,
  useStorefrontGetCartHeavyQuery,
  useStorefrontCartCreateMutation,
  type StorefrontCartHeavyFragment,
  type StorefrontGetCartHeavyQuery,
  type StorefrontAddCartLinesMutation,
  type StorefrontAddCartLinesMutationVariables,
  useStorefrontAddCartLinesMutation,
} from "../MapmakerApi";

gql`
  fragment StorefrontCheckoutLight on StorefrontCheckout {
    id
    completedAt
    lineItems(first: 100) {
      edges {
        node {
          id
          quantity
        }
      }
    }
  }

  fragment StorefrontCartLight on StorefrontCart {
    id
    lines(first: 100) {
      edges {
        node {
          quantity
          merchandise {
            ... on StorefrontProductVariant {
              id
            }
          }
        }
      }
    }
  }

  query storefrontGetCheckoutLight($checkoutId: ID!) {
    storefrontCheckout: storefrontNode(id: $checkoutId) {
      id
      ... on StorefrontCheckout {
        ...StorefrontCheckoutLight
      }
    }
  }

  query storefrontGetCartLight($cartId: ID!) {
    storefrontCart: storefrontNode(id: $cartId) {
      id
      ... on StorefrontCart {
        ...StorefrontCartLight
      }
    }
  }

  mutation storefrontCreateCheckout($input: StorefrontCheckoutCreateInput!) {
    storefrontCheckoutCreate(input: $input) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  mutation storefrontCartCreate($input: StorefrontCartInput!) {
    storefrontCartCreate(input: $input) {
      cart {
        ...StorefrontCartHeavy
      }
    }
  }

  mutation storefrontAddCheckoutLineItems(
    $checkoutId: ID!
    $lineItems: [StorefrontCheckoutLineItemInput!]!
  ) {
    storefrontCheckoutLineItemsAdd(
      checkoutId: $checkoutId
      lineItems: $lineItems
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  mutation storefrontAddCartLines(
    $cartId: ID!
    $lines: [StorefrontCartLineInput!]!
  ) {
    storefrontCartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...StorefrontCartHeavy
      }
    }
  }
`;

const STOREFRONT_CHECKOUT_ID_KEY = "mapmaker.shopify.checkoutId";
const STOREFRONT_CART_ID_KEY = "mapmaker.shopify.cartId";

/**
 * A very simple local storage value, but some extra logic to deal with merging TBL and PM
 * functionality.
 */
export function useStorefrontCheckoutId() {
  const [checkoutId] = useLiveLocalStorage<string>(STOREFRONT_CHECKOUT_ID_KEY);
  return checkoutId;
}

export function useStorefrontCartId() {
  const [cartId] = useLiveLocalStorage<string>(STOREFRONT_CART_ID_KEY);
  return cartId;
}

export function useClearStorefrontCheckoutId() {
  const [, , clearCheckoutId] = useLiveLocalStorage<string>(
    STOREFRONT_CHECKOUT_ID_KEY
  );
  return clearCheckoutId;
}

export function useClearStorefrontCartId() {
  const [, , clearCartId] = useLiveLocalStorage<string>(STOREFRONT_CART_ID_KEY);
  return clearCartId;
}

function useStorefrontCheckoutIdFull() {
  return useLiveLocalStorage<string>(STOREFRONT_CHECKOUT_ID_KEY);
}

function useStorefrontCartIdFull() {
  return useLiveLocalStorage<string>(STOREFRONT_CART_ID_KEY);
}

export function useStorefrontCheckoutLight(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCheckoutLightQuery,
      Exact<{
        checkoutId: string;
      }>
    >
  >
) {
  const checkoutId = useStorefrontCheckoutId();
  const clearCheckout = useClearStorefrontCheckoutId();
  const result = useStorefrontGetCheckoutLightQuery({
    ...queryOptions,
    skip: !checkoutId,
    variables: {
      // @ts-expect-error
      checkoutId,
    },
    onCompleted: (result) => {
      if (
        !result.storefrontCheckout ||
        !!result.storefrontCheckout["completedAt"]
      ) {
        clearCheckout();
      }
    },
  });

  const checkout =
    (result.data?.storefrontCheckout as StorefrontCheckoutLightFragment) ??
    undefined;

  return {
    checkout,
    ...result,
  };
}

export function useStorefrontCartLight(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCartLightQuery,
      Exact<{
        cartId: string;
      }>
    >
  >
) {
  const cartId = useStorefrontCartId();
  const clearCart = useClearStorefrontCartId();
  const result = useStorefrontGetCartLightQuery({
    ...queryOptions,
    skip: !cartId,
    variables: {
      // @ts-expect-error
      cartId,
    },
    onCompleted: (result) => {
      if (!result.storefrontCart) {
        clearCart();
      }
    },
  });

  const cart =
    (result.data?.storefrontCart as StorefrontCartLightFragment) ?? undefined;

  return {
    cart,
    ...result,
  };
}

export function useStorefrontCheckoutHeavy(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCheckoutHeavyQuery,
      Exact<{
        checkoutId: string;
      }>
    >
  >
) {
  const checkoutId = useStorefrontCheckoutId();
  const clearCheckout = useClearStorefrontCheckoutId();
  const result = useStorefrontGetCheckoutHeavyQuery({
    ...queryOptions,
    skip: !checkoutId,
    variables: {
      // @ts-expect-error
      checkoutId,
    },
    onCompleted: (result) => {
      const checkout =
        (result.storefrontCheckout as StorefrontCheckoutHeavyFragment) ??
        undefined;
      if (!checkout || !!checkout.completedAt) {
        clearCheckout();
      }
    },
  });

  const checkout =
    (result.data?.storefrontCheckout as StorefrontCheckoutHeavyFragment) ??
    undefined;

  if (checkout?.lineItems.edges.some((edge) => !edge.node.variant)) {
    // Old item was in cart.
    clearCheckout();
  }

  return {
    checkout,
    ...result,
  };
}

export function useStorefrontCartHeavy(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCartHeavyQuery,
      Exact<{
        cartId: string;
      }>
    >
  >
) {
  const cartId = useStorefrontCartId();
  const clearCart = useClearStorefrontCartId();
  const result = useStorefrontGetCartHeavyQuery({
    ...queryOptions,
    skip: !cartId,
    variables: {
      // @ts-expect-error
      cartId,
    },
    onCompleted: (result) => {
      if (!result.storefrontCart) {
        clearCart();
      }
    },
  });

  const cart =
    (result.data?.storefrontCart as StorefrontCartHeavyFragment) ?? undefined;

  return {
    cart,
    ...result,
  };
}

export function useStorefrontAddCheckoutLineItems(): [
  (
    lineItems: StorefrontAddCheckoutLineItemsMutationVariables["lineItems"]
  ) => Promise<
    FetchResult<
      StorefrontAddCheckoutLineItemsMutation,
      Record<string, any>,
      Record<string, any>
    >
  >,
  MutationResult<StorefrontAddCheckoutLineItemsMutation>
] {
  const { user } = useAuthContext();
  const [checkoutId, setCheckoutId] = useStorefrontCheckoutIdFull();

  // Used when we need to create the checkout.
  const [createCheckoutMutation, createCheckoutStatus] =
    useStorefrontCreateCheckoutMutation({
      variables: {
        input: {
          email: user?.email,
        },
      },
    });

  // Used once we have a checkout.
  const [addCheckoutLineItemsMutation, addCheckoutLineItemsStatus] =
    useStorefrontAddCheckoutLineItemsMutation();

  const createCheckout = async () => {
    if (checkoutId) {
      return checkoutId;
    }

    const result = await createCheckoutMutation();
    const newCheckout = result.data?.storefrontCheckoutCreate?.checkout;
    if (newCheckout) {
      setCheckoutId(newCheckout.id);
      return newCheckout.id;
    } else {
      Sentry.captureException("Error creating checkout.", {
        extra: {
          storefrontApiResult: result.data,
          storefrontApiErrors: result.errors,
        },
      });
      throw new Error(
        "Error communicating with shop. Refresh the page or contact us if the problem persists."
      );
    }
  };

  // The method exposed to consumers, accepts line items and adds them to the cart, handling
  // creation if necessary.
  const addMethod = async (
    lineItems: StorefrontAddCheckoutLineItemsMutationVariables["lineItems"]
  ) => {
    const validCheckoutId = checkoutId ?? (await createCheckout());
    return await addCheckoutLineItemsMutation({
      variables: {
        // @ts-expect-error
        checkoutId: validCheckoutId,
        lineItems,
      },
    });
  };
  return [
    addMethod,
    {
      ...addCheckoutLineItemsStatus,
      loading:
        createCheckoutStatus.loading || addCheckoutLineItemsStatus.loading,
      error: createCheckoutStatus.error || addCheckoutLineItemsStatus.error,
      called: createCheckoutStatus.called || addCheckoutLineItemsStatus.called,
    },
  ];
}

export function useStorefrontAddCartLines(): [
  (
    lines: StorefrontAddCartLinesMutationVariables["lines"]
  ) => Promise<FetchResult<StorefrontAddCartLinesMutation>>,
  MutationResult
] {
  const [cartId, setCartId] = useStorefrontCartIdFull();
  const [createCartMutation, createCartStatus] =
    useStorefrontCartCreateMutation();
  const [addCartLinesMutation, addCartLinesStatus] =
    useStorefrontAddCartLinesMutation();

  const createCart = async () => {
    if (cartId) {
      return cartId;
    }

    const result = await createCartMutation({
      variables: {
        input: {
          lines: [],
        },
      },
    });
    const newCart = result.data?.storefrontCartCreate?.cart;
    if (newCart) {
      setCartId(newCart.id);
      return newCart.id;
    } else {
      Sentry.captureException("Error creating cart.", {
        extra: {
          storefrontApiResult: result.data,
          storefrontApiErrors: result.errors,
        },
      });
      throw new Error(
        "Error communicating with shop. Refresh the page or contact us if the problem persists."
      );
    }
  };

  // The method exposed to consumers, accepts line items and adds them to the cart, handling
  // creation if necessary.
  const addMethod = async (
    lines: StorefrontAddCartLinesMutationVariables["lines"]
  ) => {
    const validCartId = cartId ?? (await createCart());
    return await addCartLinesMutation({
      variables: {
        // @ts-expect-error
        cartId: validCartId,
        lines,
      },
    });
  };

  return [
    addMethod,
    {
      ...addCartLinesStatus,
      loading: createCartStatus.loading || addCartLinesStatus.loading,
      error: createCartStatus.error || addCartLinesStatus.error,
      called: createCartStatus.called || addCartLinesStatus.called,
    },
  ];
}
