import { ComponentType, useMemo } from "react";
import { Link } from "react-router-dom";
import Suspender from "react-suspender";
import { Button, LinkButton } from "@mapmaker/ui";
import { gql } from "@apollo/client";
import {
  groupStickerLines,
  totalStickersWithoutTokenInCart,
  totalStickerTokensInCart,
} from "../../lib/storefront";
import StandardLineItem, {
  type StandardLineDetailsComponent,
} from "./StandardLine";
import StickersLineItem from "./StickersLineItem";
import CartSummary, { CartSummaryPublicProps } from "./CartSummary";
import PurchaseStickerTokensLineItem from "./PurchaseStickerTokensLineItem";
import {
  useGetStickerOrdersForCartPageQuery,
  useStorefrontCartLinesRemoveMutation,
  type StorefrontCartHeavyFragment,
} from "../../client/MapmakerApi";
import ErrorPage from "../../lib/errors/ErrorPage";
import {
  useClearStorefrontCartId,
  useStorefrontCartHeavy,
} from "../../client/storefront/storefrontCheckoutHooks";
import "./Cart.css";
import CenteredPage from "../shared/CenteredPage";
import { useMapmakerContext } from "../..";
import MapmakerMessage from "../shared/messages/MapmakerMessage";

gql`
  fragment StorefrontCheckoutHeavy on StorefrontCheckout {
    id
    completedAt
    webUrl
    totalPrice {
      amount
      currencyCode
    }
    totalTax {
      amount
      currencyCode
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    note
    requiresShipping
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    discountApplications(first: 100) {
      edges {
        node {
          ...StorefrontDiscountApplication
        }
      }
    }
    lineItems(first: 100) {
      edges {
        node {
          ...StorefrontCheckoutLineItemHeavy
        }
      }
    }
  }

  query storefrontGetCheckoutHeavy($checkoutId: ID!) {
    storefrontCheckout: storefrontNode(id: $checkoutId) {
      id
      ... on StorefrontCheckout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  mutation storefrontRemoveCheckoutLineItems(
    $checkoutId: ID!
    $lineItemIds: [ID!]!
  ) {
    storefrontCheckoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  fragment StorefrontCartHeavy on StorefrontCart {
    id
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    note
    lines(first: 100) {
      edges {
        node {
          ...StorefrontCartLineHeavy
        }
      }
    }
  }

  query storefrontGetCartHeavy($cartId: ID!) {
    storefrontCart: storefrontNode(id: $cartId) {
      id
      ... on StorefrontCart {
        ...StorefrontCartHeavy
      }
    }
  }

  mutation storefrontCartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    storefrontCartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...StorefrontCartHeavy
      }
    }
  }

  query getStickerOrdersForCartPage($ids: [String!]!) {
    stickerOrders(ids: $ids) {
      ...StickerOrderForLineItem
    }
  }
`;

export type ShippingComponentProps = {
  cart: StorefrontCartHeavyFragment;
};

export type CartProps = CartSummaryPublicProps & {
  LineItemDetailsComponent?: StandardLineDetailsComponent;
  ShippingComponent?: ComponentType<ShippingComponentProps>;
};

export default function Cart(props: CartProps) {
  /*
  const { checkout, loading, error } = useStorefrontCheckoutHeavy({
    fetchPolicy: "network-only",
  });
  const clearCheckoutId = useClearStorefrontCheckoutId();
  */
  const { cart, loading, error } = useStorefrontCartHeavy({
    fetchPolicy: "network-only",
  });
  const clearCartId = useClearStorefrontCartId();

  if (error) {
    return (
      <ErrorPage>
        There was an error loading the items in your cart. Try refreshing the
        page. If that does not work try clearing your cart.
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <Button onClick={clearCartId} size="small" color="accent">
            Clear Your Cart
          </Button>
        </div>
      </ErrorPage>
    );
  }

  if (loading) {
    return <Suspender />;
  } else if (!cart) {
    return <EmptyCartPage />;
  }

  return <CartPageWithContext cart={cart} {...props} />;
}

type CartPageWithMapmakerContextProps = CartProps & {
  cart: StorefrontCartHeavyFragment;
};

function CartPageWithContext({
  cart,
  isFreeShipping,
  FinePrintComponent,
  LineItemDetailsComponent,
  ShippingComponent,
}: CartPageWithMapmakerContextProps) {
  const { authenticatedAccount } = useMapmakerContext();
  const [removeCartLines] = useStorefrontCartLinesRemoveMutation();
  const { standardLines, stickerOrderLines, purchaseStickerTokensLines } =
    useMemo(
      () => groupStickerLines(cart.lines.edges.map(({ node }) => node)),
      [cart?.lines]
    );

  console.log(stickerOrderLines);
  const stickerOrderIds = useMemo(
    () => stickerOrderLines.map((stickerOrderLine) => stickerOrderLine.orderId),
    [stickerOrderLines]
  );

  const {
    data: { stickerOrders = undefined } = {},
    loading: loadingStickerOrders,
  } = useGetStickerOrdersForCartPageQuery({
    variables: {
      // @ts-expect-error
      ids: stickerOrderIds,
    },
  });

  if (loadingStickerOrders) {
    return <Suspender />;
  }

  const tokensRequired = totalStickerTokensInCart(cart);
  const stickersWithoutTokensInCart = totalStickersWithoutTokenInCart(cart);
  const hasEnoughTokens =
    (authenticatedAccount?.stickerTokenBalance ?? 0) >= tokensRequired;

  return (
    <div id="mapmaker-cart-page">
      <section className="main">
        <NotEnoughTokensMessage
          stickerTokenBalance={authenticatedAccount?.stickerTokenBalance ?? 0}
          tokensRequired={tokensRequired}
        />
        <TokensAvailableMessage
          tokensAvailable={authenticatedAccount?.stickerTokenBalance ?? 0}
          stickersWithoutTokensInCart={stickersWithoutTokensInCart}
        />

        {cart.lines.edges.length ? (
          <div className="line-items">
            {standardLines.map((line) => {
              return (
                <StandardLineItem
                  key={line.id}
                  line={line}
                  DetailsComponent={LineItemDetailsComponent}
                />
              );
            })}
            {stickerOrderLines
              .filter((x) => !!x)
              .map((group) => {
                try {
                  const stickerOrder = stickerOrders?.find(
                    (item) => item.id === group.orderId
                  );
                  return (
                    <StickersLineItem
                      key={group.orderId}
                      group={group}
                      stickerOrder={stickerOrder}
                    />
                  );
                } catch (e) {
                  // This can happen if the cart has sticker orders from another user, and
                  // therefore they do not have access to the data from this order.
                  removeCartLines({
                    variables: {
                      // @ts-expect-error
                      cartId: cart.id,
                      // @ts-expect-error
                      lineIds: group.lines.map((line) => line.id),
                    },
                  });
                  return null;
                }
              })}

            {purchaseStickerTokensLines.map((line) => {
              return (
                <PurchaseStickerTokensLineItem
                  key={line.id}
                  line={line}
                  /* If we have other items then we will also mail the code. */
                  willBeMailed={
                    standardLines.length + stickerOrderLines.length > 0
                  }
                />
              );
            })}
          </div>
        ) : (
          <EmptyCartPage />
        )}
      </section>
      <section className="summary">
        <CartSummary
          disabled={!hasEnoughTokens}
          FinePrintComponent={FinePrintComponent}
          isFreeShipping={isFreeShipping}
        />
        {ShippingComponent && <ShippingComponent cart={cart} />}
      </section>
    </div>
  );
}

function EmptyCartPage() {
  return (
    <CenteredPage style={{ height: "300px" }}>
      <h2>Your cart is empty.</h2>
      <LinkButton to="/shop" color="accent">
        Fill it up!
      </LinkButton>
    </CenteredPage>
  );
}

interface INotEnoughTokensMessageProps {
  tokensRequired: number;
  stickerTokenBalance: number;
}

function NotEnoughTokensMessage({
  tokensRequired,
  stickerTokenBalance,
}: INotEnoughTokensMessageProps) {
  const tokenAmount = (tokens: number) => (
    <strong>{tokens === 1 ? `${tokens} token` : `${tokens} tokens`}</strong>
  );

  if (tokensRequired <= stickerTokenBalance) {
    return null;
  }

  return (
    <MapmakerMessage severity="error" closeable={false}>
      <div>
        <p>
          You need {tokenAmount(tokensRequired)} for the stickers in your cart
          but you have {tokenAmount(stickerTokenBalance)} in{" "}
          <Link to="/account/sticker-tokens">your account</Link>.
        </p>
        <p>Remove one or more of the items below in order to check out.</p>
      </div>
    </MapmakerMessage>
  );
}

type TokensAvailableMessageProps = {
  tokensAvailable: number;
  stickersWithoutTokensInCart: number;
};

function TokensAvailableMessage({
  tokensAvailable,
  stickersWithoutTokensInCart,
}: TokensAvailableMessageProps) {
  const tokenAmount = (tokens: number) => (
    <strong>
      {tokens === 1 ? `${tokens} sticker token` : `${tokens} sticker tokens`}
    </strong>
  );

  if (tokensAvailable === 0 || stickersWithoutTokensInCart === 0) {
    return null;
  }

  return (
    <MapmakerMessage severity="info">
      <div>
        <p>
          You have {tokenAmount(tokensAvailable)} in your account which can
          cover the cost of some or all of your stickers. If you check out now
          you will be asked to pay for the stickers though. To use the tokens
          follow these steps:
        </p>
        <ol>
          <li>Remove the stickers from your cart.</li>
          <li>Return to the project and select "Order Stickers"</li>
          <li>Ensure "Use X Sticker Tokens" is selected.</li>
          <li>Add the stickers back to your cart.</li>
        </ol>
      </div>
    </MapmakerMessage>
  );
}
